import axios from 'axios'

 
export default {

    async getAll(params,callback){
        return await axios.post("/app/dashboard/sale/customerinvoices/getall",params)
        .then(res=>{
            callback(res)
        })
        .catch(err=>{
            console.log('response error '+err)
        })
    },


    async add(params,callback){
        return await axios.post("/app/dashboard/sale/customerinvoices/add",params)
        .then(res=>{
            callback(res)
        })
        .catch(err=>{
            console.log('response error '+err)
        })
    },


    async edit(params,callback){
        return await axios.post("/app/dashboard/sale/customerinvoices/edit",params)
        .then(res=>{
            callback(res)
        })
        .catch(err=>{
            console.log('response error '+err)
        })
    },

    async delete(params,callback){
        return await axios.post("/app/dashboard/sale/customerinvoices/delete",params)
        .then(res=>{
            callback(res)
        })
        .catch(err=>{
            console.log('response error '+err)
        })
    },


    async getOne(params,callback){
        return await axios.post("/app/dashboard/sale/customerinvoices/getone",params)
        .then(res=>{
            callback(res)
        })
        .catch(err=>{ 
            console.log('response error '+err)
        })
    },


    async changeAvatar(params,config,callback){
        return await axios.post("/app/dashboard/sale/customerinvoices/changeavatar",params,config)
        .then(res=>{
            callback(res)
        })
        .catch(err=>{
            console.log('response error '+err)
        })
    },
    
    async discount(params,callback){
        return await axios.post("/app/dashboard/sale/customerinvoices/discount",params)
        .then(res=>{
            callback(res)
        })
        .catch(err=>{
            console.log('response error '+err)
        })
    },

    async changeDriver(params,callback){
        return await axios.post("/app/dashboard/sale/customerinvoices/changedriver",params)
        .then(res=>{
            callback(res)
        })
        .catch(err=>{
            console.log('response error '+err)
        })
    },

    async driverwage(params,callback){
        return await axios.post("/app/dashboard/sale/customerinvoices/driverwage",params)
        .then(res=>{
            callback(res)
        })
        .catch(err=>{
            console.log('response error '+err)
        })
    },


}
 
