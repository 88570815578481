<template>
  <div>
    <navbar></navbar>
    <v-container fluid class="mt-4 mb-4 p-4">
      <v-row>
        <v-col cols="11" sm="11" md="11" lg="11" xl="11" class="mx-auto">
          <center>
            <h1 class="mb-5 mt-5">{{ langkeyword("REPORT") }}</h1>
          </center>

          <v-expansion-panels :mandatory="dates.date1 != null && dates.date2 != null" popout hover focusable>
            <v-expansion-panel class="mode">
              <v-expansion-panel-header class="mode" v-slot="{ open }">
                <v-row no-gutters>
                  <v-col cols="4">
                    {{ langkeyword("REPORT") }}
                  </v-col>
                  <v-col cols="8" class="text--secondary">
                    <v-fade-transition leave-absolute>
                      <span v-if="open">{{ langkeyword("SELECTDATES") }}</span>
                      <v-row v-else no-gutters style="width: 100%">
                        <v-col cols="6">
                          {{ langkeyword("DATE1") }}: {{ dates.date1 || "" }}
                        </v-col>
                        <v-col cols="6">
                          {{ langkeyword("DATE2") }}: {{ dates.date2 || "" }}
                        </v-col>
                      </v-row>
                    </v-fade-transition>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mode">
                <v-row justify="space-around" no-gutters>
                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-menu ref="date1Menu" :close-on-content-click="false" :return-value.sync="dates.date1" offset-y
                      min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field filled v-model="dates.date1" :label="langkeyword('DATE1')"
                          prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$refs.date1Menu.isActive = false">
                          {{ langkeyword("CANCEL") }}
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.date1Menu.save(date)">
                          {{ langkeyword("OK") }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-menu ref="date2Menu" :close-on-content-click="false" :return-value.sync="dates.date2" offset-y
                      min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field filled v-model="dates.date2" :label="langkeyword('DATE2')"
                          prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$refs.date2Menu.isActive = false">
                          {{ langkeyword("CANCEL") }}
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.date2Menu.save(date)">
                          {{ langkeyword("OK") }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-select v-model="company_id" :items="companies" item-text="name" item-value="id"
                      prepend-icon="mdi mdi-domain" :label="langkeyword('COMPANY')" dense clearable filled></v-select>
                  </v-col>
                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-select v-model="customer_id" :items="customers" item-text="name" item-value="id"
                      prepend-icon="mdi mdi-face-agent" :label="langkeyword('CUSTOMER')" dense clearable
                      filled></v-select>
                  </v-col>
                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-select v-model="product_id" :items="products" :item-text="langoptions('value') + '_name'"
                      item-value="id" prepend-icon="mdi mdi-shopping" :label="langkeyword('PRODUCT')" dense clearable
                      filled></v-select>
                  </v-col>
                </v-row>

                <center>
                  <v-btn @click="saveReport" v-if="dates.date1 != null &&
              dates.date2 != null &&
              permissionExist('#search_generalreports')
              " class="m-auto bg-main text-center white--text">
                    {{ langkeyword("GETREPORT") }}
                  </v-btn>
                </center>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="reportCards != false">
      <v-row no-gutters>
        <v-col cols="12" sm="11" md="10" lg="6" xl="6" class="ma-auto">
          <v-card elevation="1" shaped class="pa-2 mx-1 mt-2 py-1">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-4">{{
              langkeyword("BUY")
            }}</v-icon>
                </v-list-item-title>

                <div class="text-center">
                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarbuy) }} -
                    {{ langkeyword("TOTAL") }}

                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdollarbuy }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarbuy) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdinarbuy }}</v-btn>
                  </v-chip>

                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarbuycash) }} -
                    {{ langkeyword("CASH") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdollarbuycash }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarbuydebt) }} -
                    {{ langkeyword("DEBT") }}

                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdollarbuydebt }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarbuyfree) }} -
                    {{ langkeyword("FREE") }}

                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdollarbuyfree }}</v-btn>
                  </v-chip>

                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarbuycash) }} -
                    {{ langkeyword("CASH") }}

                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdinarbuycash }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarbuydebt) }} -
                    {{ langkeyword("DEBT") }}

                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdinarbuydebt }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarbuyfree) }} -
                    {{ langkeyword("FREE") }}

                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdinarbuyfree }}</v-btn>
                  </v-chip>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <v-col cols="12" sm="11" md="10" lg="6" xl="6" class="ma-auto">
          <v-card elevation="1" shaped class="pa-2 mx-1 mt-2 py-1">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-4">{{
              langkeyword("EXPENSE")
            }}</v-icon>
                </v-list-item-title>

                <div class="text-center">
                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarexpense) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdollarexpense }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarexpense) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdinarexpense }}</v-btn>
                  </v-chip>

                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarexpensecash) }} -
                    {{ langkeyword("CASH") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdollarexpensecash }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarexpensedebt) }} -
                    {{ langkeyword("DEBT") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdollarexpensedebt }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarexpensefree) }} -
                    {{ langkeyword("FREE") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdollarexpensefree }}</v-btn>
                  </v-chip>

                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarexpensecash) }} -
                    {{ langkeyword("CASH") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdinarexpensecash }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarexpensedebt) }} -
                    {{ langkeyword("DEBT") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdinarexpensedebt }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarexpensefree) }} -
                    {{ langkeyword("FREE") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdinarexpensefree }}</v-btn>
                  </v-chip>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <v-col cols="12" sm="11" md="10" lg="6" xl="6" class="ma-auto">
          <v-card elevation="1" shaped class="pa-2 mx-1 mt-2 py-1">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-4">{{
              langkeyword("SALE")
            }}</v-icon>
                </v-list-item-title>

                <div class="text-center">
                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarsale) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdollarsale }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarsale) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdinarsale }}</v-btn>
                  </v-chip>

                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarsalecash) }} -
                    {{ langkeyword("CASH") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdollarsalecash }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarsaledebt) }} -
                    {{ langkeyword("DEBT") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdollarsaledebt }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarsalefree) }} -
                    {{ langkeyword("FREE") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdollarsalefree }}</v-btn>
                  </v-chip>

                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarsalecash) }} -
                    {{ langkeyword("CASH") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdinarsalecash }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarsaledebt) }} -
                    {{ langkeyword("DEBT") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdinarsaledebt }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarsalefree) }} -
                    {{ langkeyword("FREE") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdinarsalefree }}</v-btn>
                  </v-chip>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <v-col cols="12" sm="11" md="10" lg="6" xl="6" class="ma-auto">
          <v-card elevation="1" shaped class="pa-2 mx-1 mt-2 py-1">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-2">
                    {{ langkeyword("RETURNDEBT") }} ــ {{ langkeyword("BUY") }}
                  </v-icon>
                </v-list-item-title>

                <div class="text-center">
                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarreturnbuydebt) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdollarreturnbuydebt }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarreturnbuydebt) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdinarreturnbuydebt }}</v-btn>
                  </v-chip>

                  <v-divider></v-divider>
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-2">
                    {{ langkeyword("RETURNDEBT") }} ــ
                    {{ langkeyword("SALE") }}</v-icon>
                </v-list-item-title>

                <div class="text-center">
                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarreturnsaledebt) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdollarreturnsaledebt }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarreturnsaledebt) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
              reportData.countdinarreturnsaledebt }}</v-btn>
                  </v-chip>

                  <v-divider></v-divider>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>




        <v-col cols="12" sm="11" md="10" lg="6" xl="6" class="ma-auto">
          <v-card elevation="1" shaped class="pa-2 mx-1 mt-2 py-1">

            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-4">{{ langkeyword("BASICDEBT") }} ــ {{ langkeyword("BUY") }}</v-icon>
                </v-list-item-title>
                <div class="text-center">
                  <v-divider></v-divider>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totalDollarBuyBasicDebt) }}
                  </v-chip>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totalDinarBuyBasicDebt) }}
                  </v-chip>
                  <v-divider></v-divider>
                </div>
              </v-list-item-content>
            </v-list-item>


            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-4">{{ langkeyword("BASICDEBT") }} ــ {{ langkeyword("SALE") }}</v-icon>
                </v-list-item-title>
                <div class="text-center">
                  <v-divider></v-divider>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totalDollarSaleBasicDebt) }}
                  </v-chip>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totalDinarSaleBasicDebt) }}
                  </v-chip>
                  <v-divider></v-divider>
                </div>
              </v-list-item-content>
            </v-list-item>

          </v-card>
        </v-col>






        <v-col cols="12" sm="11" md="10" lg="6" xl="6" class="ma-auto">
          <v-card elevation="1" shaped class="pa-2 mx-1 mt-2 py-1">

            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-4">{{ langkeyword("TOTALDEBT") }} ــ {{
              langkeyword("BUY") }}</v-icon>
                </v-list-item-title>
                <div class="text-center">
                  <v-divider></v-divider>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">

                    {{ dollar(reportData.totalDollarBuyBasicDebt + reportData.totaldollarbuydebt) }}
                  </v-chip>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totalDinarBuyBasicDebt + reportData.totaldinarbuydebt) }}
                  </v-chip>
                  <v-divider></v-divider>
                </div>
              </v-list-item-content>
            </v-list-item>


            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-4">{{ langkeyword("TOTALDEBT") }} ــ {{
                    langkeyword("SALE") }}</v-icon>
                </v-list-item-title>
                <div class="text-center">
                  <v-divider></v-divider>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totalDollarSaleBasicDebt+reportData.totaldollarsaledebt) }}
                  </v-chip>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totalDinarSaleBasicDebt+reportData.totaldinarsaledebt) }}
                  </v-chip>
                  <v-divider></v-divider>
                </div>
              </v-list-item-content>
            </v-list-item>

          </v-card>
        </v-col>




      </v-row>
    </v-container>

    <br /><br /><br />
    <dashboardFooter />
  </div>
</template>
<script>
import GeneralReportRequests from "../../../requests/dashboard/accounting/GeneralReport";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
export default {
  components: {
    navbar,
    dashboardFooter,
  },
  data: () => ({
    date: null,
    dates: {
      date1: null,
      date2: null,
    },
    reportCards: false,
    product_id: null,
    company_id: null,
    customer_id: null,
    reportData: {},
  }),
  watch: {
    options: {
      handler() { },
    },
  },
  mounted() {
    setTimeout(() => {
      if (!this.permissionExist("#view_generalreports")) {
        this.$router.push("/dashboard/home");
      }
      this.getAllReport()
    }, 500);
  },
  computed: {
    companies() {
      return this.$store.getters.getcompanies;
    },
    customers() {
      return this.$store.getters.getcustomers;
    },
    products() {
      return this.$store.getters.getproducts;
    },
  },
  methods: {
    saveReport() {
      GeneralReportRequests.byDate(
        {
          byDatePublicReportApp: true,
          date1: this.dates.date1,
          date2: this.dates.date2,
          product_id: this.product_id,
          company_id: this.company_id,
          customer_id: this.customer_id,
        },
        (res) => {
          console.log(res.data)
          this.reportCards = true;
          this.reportData = res.data;
        }
      );
    },
    getAllReport() {
      GeneralReportRequests.getAll(
        {
          getAllPublicReportApp: true
        },
        (res) => {
          this.reportCards = true;
          this.reportData = res.data;
        }
      );
    },
  },
};
</script>
