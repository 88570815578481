import axios from 'axios'

 
export default {


    async getAllReturnDebt(params,callback){
        return await axios.post("/app/dashboard/debt/companies/getallreturndebt",params)
        .then(res=>{
            callback(res)
        })
        .catch(err=>{
            console.log('response error '+err)
        })
    },



    async getOne(params,callback){
        return await axios.post("/app/dashboard/debt/companies/getone",params)
        .then(res=>{
            callback(res)
        })
        .catch(err=>{
            console.log('response error '+err)
        })
    },



    async returnDebt(params,callback){
        return await axios.post("/app/dashboard/debt/companies/returndebt",params)
        .then(res=>{
            callback(res)
        })
        .catch(err=>{
            console.log('response error '+err)
        })
    },



    async deleteReturnedDebt(params,callback){
        return await axios.post("/app/dashboard/debt/companies/deletereturneddebt",params)
        .then(res=>{
            callback(res)
        })
        .catch(err=>{
            console.log('response error '+err)
        })
    },



}
 
