<template>
    <div>
      <navbar></navbar>
      <v-container class="mb-4">
        <v-row class="alert mt-4">
          <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="error_msg != null">
            <v-alert dense type="warning">{{ error_msg }}</v-alert>
          </v-col>
          <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="success_msg != null">
            <v-alert dense type="success">{{ success_msg }}</v-alert>
          </v-col>
        </v-row>
  
        <v-row class="text-center">
          <v-col cols="12 text-start mx-auto" sm="11">
            <v-btn @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
              class="bg-main mb-2 mr-2 h-100 radius-5 nzar-font white--text">
              <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
              <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
              <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
              {{ langkeyword("BACK") }}
            </v-btn>
          </v-col>
  
          <v-col cols="12 card text-start mx-auto" sm="11">
            <v-card>
              <v-card-title class="bg-main darken-1 mx-auto">
                <span class="white--text">{{ langkeyword("ROLE") }}</span>
              </v-card-title>
  
              <v-list>
                <v-list-item>
                  <v-list-item-action> </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ langkeyword("NAME") }} :
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <span v-if="lang() === 'ku'">{{ roleinfo.ku_name }}</span>
                    <span v-if="lang() === 'ar'">{{ roleinfo.ar_name }}</span>
                    <span v-if="lang() === 'en'">{{ roleinfo.en_name }}</span>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-row class="mt-1 alert pa-2">
          <v-col cols="12 text-center px-2 mx-auto mt-1" sm="11">
            <br />
            <v-card shaped class="mt-2 mb-2 pa-1">
              <v-combobox class="bg-mode pa-2 px-3" v-model="selects" :items="items" :label="langkeyword('PERMISSIONS')"
                multiple outlined clearable prepend-icon="mdi-filter-variant" hide-selected persistent-hint small-chips
                solo></v-combobox>
  
              <div class="text-center ma-auto pa-1">
                <v-btn @click="savePermissionsToRole" class="bg-main mx-3 white--text mx-auto text-center" x-large
                  rounded>
                  <v-icon dark> mdi-plus-circle</v-icon>
                  {{ langkeyword("SAVE") }}
                </v-btn>
  
                <v-btn @click="restart" color="red" class="mx-3 white--text mx-auto text-center" x-large rounded>
                  <v-icon dark> mdi-refresh-circle</v-icon>
                  {{ langkeyword("RESET") }}
                </v-btn>
  
                <v-btn @click="selectALL" color="primary" class="mx-3 white--text mx-auto text-center" x-large rounded>
                  <v-icon dark> mdi-check-circle</v-icon>
                  {{ langkeyword("SELECTALL") }}
                </v-btn>
              </div>
            </v-card>
            <br />
          </v-col>
        </v-row>
  
      </v-container>
      <br /><br /><br />
      <dashboardFooter />
    </div>
  </template>
  
  <script>
  import RolesRequests from "../../../requests/dashboard/account/Roles";
  import navbar from "../../../layouts/dashboard/nav.vue";
  import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
  export default {
    components: {
      navbar,
      dashboardFooter,
    },
    data() {
      return {
        roleinfo: {},
        error_msg: null,
        success_msg: null,
        selects: [],
        items: [],
      };
    },
    watch: {
      options: {
        handler() {
          this.readData();
        },
      },
    },
    mounted() {
      this.readData();
    },
    computed: {
      roles() {
        return this.$store.getters.getroles;
      },
      permissions() {
        return this.$store.getters.getpermissions;
      },
    },
    methods: {
      cleanMessages() {
        setTimeout(() => {
          this.success_msg = null;
          this.error_msg = null;
        }, 3000);
      },
      validate(params, callback) {
        this.error_msg = null;
        params.forEach((param) => {
          if (param == "" || param == undefined || param == null) {
            this.error_msg = this.langkeyword("DATA_REQUIRED");
          }
        });
        if (this.error_msg == null) {
          return callback();
        }
        this.cleanMessages();
      },
      readData() {
        setTimeout(() => {
          this.checkAll();
          // console.log(this.$route.params.id)
          if (
            !this.permissionExist("#givepermissions_roles") ||
            (this.$store.state.dashboardauth.role_id != 1 &&
              this.$route.params.id == 1)
          ) {
            this.$router.push("/dashboard/home");
          }
        }, 100);
  
        //
      },
  
      savePermissionsToRole() {
        if (
          this.items.length > 0 ||
          this.items != undefined ||
          this.selects.length > 0 ||
          this.selects != undefined
        ) {
          let newselects = "";
          this.selects.filter((p) => {
            newselects += p.code + ",";
          });
          console.log(this.roleinfo.id);
          RolesRequests.savePermissions(
            {
              savePermissions: true,
              role_id: this.roleinfo.id,
              permissions: newselects,
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
              }
              this.cleanMessages();
            }
          );
          console.log(newselects);
        }
      },
      checkAll() {
        this.items = [];
        this.selects = [];
        RolesRequests.getOne(
          {
            getOneRoles: true,
            role_id: this.$route.params.id,
          },
          (res) => {
            if (res.data.status === false) {
              this.error_msg = this.langkeyword(res.data.msg);
              setTimeout(() => {
                this.$router.push("/*");
              }, 1000);
            } else if (res.data.status === true) {
              this.error_msg = null;
              let roleinfodata = {};
              roleinfodata = res.data.data;
              this.roleinfo = roleinfodata;
  
              if (this.$route.params.id != 1) {
                if (this.lang() === "ku") {
                  this.$store.getters.getpermissions
                    .filter((p) => {
                      return p.id >= 16;
                    })
                    .filter((p) => {
                      this.items.push({ text: p.ku_name, code: p.code });
                    });
                  res.data.rolepermissions
                    .filter((p) => {
                      return p.id >= 16;
                    })
                    .filter((p) => {
                      this.selects.push({ text: p.ku_name, code: p.code });
                    });
                } else if (this.lang() === "ar") {
                  this.$store.getters.getpermissions
                    .filter((p) => {
                      return p.id >= 16;
                    })
                    .filter((p) => {
                      this.items.push({ text: p.ar_name, code: p.code });
                    });
                  res.data.rolepermissions
                    .filter((p) => {
                      return p.id >= 16;
                    })
                    .filter((p) => {
                      this.selects.push({ text: p.ar_name, code: p.code });
                    });
                } else if (this.lang() === "en") {
                  this.$store.getters.getpermissions
                    .filter((p) => {
                      return p.id >= 16;
                    })
                    .filter((p) => {
                      this.items.push({ text: p.en_name, code: p.code });
                    });
                  res.data.rolepermissions
                    .filter((p) => {
                      return p.id >= 16;
                    })
                    .filter((p) => {
                      this.selects.push({ text: p.en_name, code: p.code });
                    });
                }
              }
              else {
                if (this.lang() === "ku") {
                  this.$store.getters.getpermissions
                    .filter((p) => {
                      this.items.push({ text: p.ku_name, code: p.code });
                    });
                  res.data.rolepermissions
                    .filter((p) => {
                      this.selects.push({ text: p.ku_name, code: p.code });
                    });
                } else if (this.lang() === "ar") {
                  this.$store.getters.getpermissions
                    .filter((p) => {
                      this.items.push({ text: p.ar_name, code: p.code });
                    });
                  res.data.rolepermissions
                    .filter((p) => {
                      this.selects.push({ text: p.ar_name, code: p.code });
                    });
                } else if (this.lang() === "en") {
                  this.$store.getters.getpermissions
                    .filter((p) => {
                      this.items.push({ text: p.en_name, code: p.code });
                    });
                  res.data.rolepermissions
                    .filter((p) => {
                      this.selects.push({ text: p.en_name, code: p.code });
                    });
                }
              }
  
  
            }
            this.cleanMessages();
          }
        );
      },
      selectALL() {
        this.selects = [];
        this.selects = this.items;
      },
      restart() {
        this.checkAll();
      },
    },
  };
  </script>