<template>
  <div>
    <navbar></navbar>

    <v-container>
      <v-row no-gutters>
        <v-col cols="6" xs="5" sm="4" md="3" lg="3" xl="2" v-for="(item, index) in cards" :key="index"
          :class="item.permissionexist == true ? 'm-auto' : 'd-none'">
          <v-card elevation="10" link :to="item.link" shaped class="p-2 mx-1 mt-2 py-1">
            <v-list-item dense>
              <v-list-item-content>
                <div class="fs-small nzar">
                  {{ item.name }}
                </div>
                <v-list-item-avatar tile size="35" class="rounded">
                  <v-icon class="text-main" :color="item.color" large>{{ item.icon }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-title class="text-h6 mt-1">
                  {{ item.num }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div class="mt-16"></div>
    <dashboardFooter />
  </div>
</template>

<script>
import navbar from "../../layouts/dashboard/nav.vue";
import NavRequests from "../../requests/dashboard/Nav";
import dashboardFooter from "../../layouts/dashboard/dashboardFooter.vue";
export default {
  data() {
    return {
      cards: [],
    };
  },
  components: {
    navbar,
    dashboardFooter,
  },
  methods: {

    authCheck() {
      NavRequests.checkAuth(
        {
          isAuthCheck: true,
          user_id: this.dashboardAuthId(),
          token: this.dashboardAuthToken(),
        },
        (res) => {
          if (res.data.status === false) {
            this.$router.push("/dashboard/auth/login");
          } else if (res.data.status === true) {
            this.$store.state.dashboardauth = res.data.data;
            this.menus = res.data.menus
            this.avatar = this.$store.state.dashboardauth.avatar;
            this.username = this.$store.state.dashboardauth.name;
            this.logo = this.$store.state.setting.logo;
          }
        }
      );
    },

    fetchCards() {
      setTimeout(() => {
        this.cards = [
          {
            name: this.langkeyword("USERS"),
            icon: "mdi-account",
            num: this.$store.state.dashboardhome.countusers - 1,
            color: "darken-1",
            link: "/dashboard/account/users",
            permissionexist: this.permissionExist('#view_users')
          },
          {
            name: this.langkeyword("ROLES"),
            icon: "mdi-security",
            num: this.$store.state.dashboardhome.countroles,
            color: "darken-1",
            link: "/dashboard/account/roles",
            permissionexist: this.permissionExist('#view_roles')
          },
          {
            name: this.langkeyword("CITIES"),
            icon: "mdi-city",
            num: this.$store.state.dashboardhome.countcities,
            color: "darken-1",
            link: "/dashboard/product/cities",
            permissionexist: this.permissionExist('#view_cities')
          },
          {
            name: this.langkeyword("STORES"),
            icon: "mdi-store",
            num: this.$store.state.dashboardhome.countstores,
            color: "darken-1",
            link: "/dashboard/product/stores",
            permissionexist: this.permissionExist('#view_stores')
          },
          {
            name: this.langkeyword("UNITS"),
            icon: "mdi-arrow-split-horizontal",
            num: this.$store.state.dashboardhome.countunits,
            color: "darken-1",
            link: "/dashboard/product/units",
            permissionexist: this.permissionExist('#view_units')
          },
          {
            name: this.langkeyword("PRODUCTCATEGORIES"),
            icon: "mdi-clipboard-list",
            num: this.$store.state.dashboardhome.countproductcategories,
            color: "darken-1",
            link: "/dashboard/product/productcategories",
            permissionexist: this.permissionExist('#view_productcategories')
          },
          {
            name: this.langkeyword("BRANDS"),
            icon: "mdi-watermark",
            num: this.$store.state.dashboardhome.countbrands,
            color: "darken-1",
            link: "/dashboard/product/brands",
            permissionexist: this.permissionExist('#view_brands')
          },
          {
            name: this.langkeyword("EXPENSECATEGORIES"),
            icon: "mdi-clipboard-list",
            num: this.$store.state.dashboardhome.countexpensecategories,
            color: "darken-1",
            link: "/dashboard/expense/expensecategories",
            permissionexist: this.permissionExist('#view_expensecategories')
          },
          {
            name: this.langkeyword("CURRENCIES"),
            icon: "mdi-currency-eur",
            num: this.$store.state.dashboardhome.countcurrencies,
            color: "darken-1",
            link: "/dashboard/invoice/currencies",
            permissionexist: this.permissionExist('#view_currencies')
          },
          {
            name: this.langkeyword("INVOICETYPES"),
            icon: "mdi-notebook-plus",
            num: this.$store.state.dashboardhome.countinvoicetypes,
            color: "darken-1",
            link: "/dashboard/invoice/invoicetypes",
            permissionexist: this.permissionExist('#view_invoicetypes')
          },
          {
            name: this.langkeyword("PRODUCTS"),
            icon: "mdi-basket",
            num: this.$store.state.dashboardhome.countproducts,
            color: "darken-1",
            link: "/dashboard/product/products",
            permissionexist: this.permissionExist('#view_products')
          },
          {
            name: this.langkeyword("COMPANIES"),
            icon: "mdi-domain",
            num: this.$store.state.dashboardhome.countcompanies,
            color: "darken-1",
            link: "/dashboard/buy/companies",
            permissionexist: this.permissionExist('#view_companies')
          },
          {
            name: this.langkeyword("CUSTOMERS"),
            icon: "mdi-face-agent",
            num: this.$store.state.dashboardhome.countcustomers,
            color: "darken-1",
            link: "/dashboard/sale/customers",
            permissionexist: this.permissionExist('#view_customers')
          },
          {
            name: this.langkeyword("EXPENSES"),
            icon: "mdi-cash-minus",
            num: this.$store.state.dashboardhome.countexpenses,
            color: "darken-1",
            link: "/dashboard/expense/expenses",
            permissionexist: this.permissionExist('#view_expenses')
          },
          {
            name: this.langkeyword("COMPANYINVOICES"),
            icon: "mdi-receipt",
            num: this.$store.state.dashboardhome.countcompanyinvoices,
            color: "darken-1",
            link: "/dashboard/buy/companyinvoices",
            permissionexist: this.permissionExist('#view_companyinvoices')
          },
          {
            name: this.langkeyword("CUSTOMERINVOICES"),
            icon: "mdi-receipt",
            num: this.$store.state.dashboardhome.countcustomerinvoices,
            color: "darken-1",
            link: "/dashboard/sale/customerinvoices",
            permissionexist: this.permissionExist('#view_customerinvoices')
          },
        ]
      }, 1000);
    }
  },
  mounted() {
    this.fetchCards()
    this.authCheck()
  },
};
</script>


<style>
@font-face {
  font-family: "nzar";
  src: local("nzar"), url(../../assets/fonts/nzar.ttf) format("truetype");
}

.radius-rounded {
  border-radius: 15px !important;
}

.nzar {
  font-family: "nzar" !important;
}

.fs-small {
  font-size: 0.9rem;
}

.theme--dark .dashboard .home-card:hover {
  background: #393b56 !important;
  transition: ease 0.2s !important;
}

.theme--light .dashboard .home-card:hover {
  background: #eceaea !important;
  transition: ease 0.2s !important;
}
</style>